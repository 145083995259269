import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useInOnApp } from '../hooks/use-in-on-app'
import { useAuth } from 'src/components/providers/AuthProvider'
import { config } from 'src'

const REDIRECT_TO_APP = process.env.NEXT_PUBLIC_MAJELAN_URL
const REDIRECT_TO_WEBAPP = `${process.env.NEXT_PUBLIC_WEBAPP_URL}/account/subscription`
const REDIRECT_TO_AUTH = '/auth/signin'
const REDIRECT_TO_AUTH_SIGNUP = '/auth/signup'
const REDIRECT_TO_ACTIVATE = '/app/activate'

// eslint-disable-next-line react/display-name
export const withProtectedAuth = (WrappedComponent: any) => ({ ...props }: any) => {
  const { user } = useAuth()

  const [isReady, setIsready] = useState(() => Boolean(user))

  const { push, query, isReady: isReadyRouter, pathname } = useRouter()
  const { isOnApp, isOnAuth, isOnTerms } = useInOnApp()

  const handleRedirect = () => {
    if (isOnApp && !user) {
      return push({ pathname: query?.code ? REDIRECT_TO_AUTH_SIGNUP : REDIRECT_TO_AUTH, query })
    }

    if (isOnAuth && user) {
      if (pathname.includes('activation') && query?.partnerServiceId) {
        push({
          pathname: config.PARTNER_BYTEL_AUTHORIZE,
          search: `response_type=code&client_id=${
            config.PARTNER_BYTEL_CLIENT_ID
          }&redirect_uri=${encodeURIComponent(
            `${config.APP_URL}/app/partner/activate?partnerServiceId=${query.partnerServiceId}`,
          )}`,
        })
        return
      }

      return push({
        pathname: query?.code
          ? REDIRECT_TO_ACTIVATE
          : query?.continue
          ? REDIRECT_TO_APP
          : REDIRECT_TO_WEBAPP,
        query,
      })
    }

    if (isOnTerms && user) {
      if (query?.partnerServiceId) {
        return push({
          pathname: config.PARTNER_BYTEL_AUTHORIZE,
          search: `response_type=code&client_id=${
            config.PARTNER_BYTEL_CLIENT_ID
          }&redirect_uri=${encodeURIComponent(
            `${config.APP_URL}/app/partner/activate?partnerServiceId=${query.partnerServiceId}`,
          )}`,
        })
      }

      return push({
        pathname: query?.code
          ? REDIRECT_TO_ACTIVATE
          : query?.continue
          ? REDIRECT_TO_APP
          : REDIRECT_TO_WEBAPP,
        query,
      })
    }

    if (isOnApp) {
      if (!user) {
        return push({ pathname: REDIRECT_TO_AUTH, query })
      }
    }

    setIsready(true)
  }

  useEffect(() => {
    if (isReadyRouter) {
      handleRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isReadyRouter])

  if (!isReady) {
    return null
  }

  return <WrappedComponent {...props} />
}
