import React from 'react'
import Link from 'next/link'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { Formik, FormikValues, Field, Form } from 'formik'
import { useRouter } from 'next/router'
import classnames from 'classnames'

import { Button } from '../atoms/button/Button'
import { useAuth } from '../providers/AuthProvider'
import { Icon } from '..'
import {
  validatePasswordRule,
  passwordRuleLength,
  passwordRuleLowercase,
  passwordRuleNumber,
  passwordRuleSpecial,
  passwordRuleUppercase,
} from 'src'
import { CheckboxField } from '../form/CheckboxField'

const SignInValidationSchema = Yup.object().shape({
  email: Yup.string().trim().required().email(),
  password: Yup.string().required(),
})

const SignUpValidationSchema = Yup.object().shape({
  email: Yup.string().trim().required('common.form.field.error.email.empty').email(),
  email_confirmation: Yup.string()
    .required('common.form.field.error.email.empty')
    .oneOf([Yup.ref('email')], 'Must be the same of password'),
  password: Yup.string()
    .required('common.form.field.error.password.empty')
    .matches(passwordRuleLength, 'common.form.field.error.password.length')
    .matches(passwordRuleUppercase, 'common.form.field.error.password.uppercase')
    .matches(passwordRuleLowercase, 'common.form.field.error.password.lowercase')
    .matches(passwordRuleNumber, 'common.form.field.error.password.number')
    .matches(passwordRuleSpecial, 'common.form.field.error.password.special'),
  newsletter: Yup.boolean(),
})

interface AuthEmailProps {
  submitLabel: string
  authMethodType: string
}

export const AuthEmail = ({ submitLabel, authMethodType }: AuthEmailProps) => {
  const { formatMessage: getI18nKey } = useIntl()
  const auth = useAuth()
  const { pathname, query } = useRouter()

  const isSignin = Boolean(authMethodType === 'signin')
  const mutate = isSignin ? auth.signIn : auth.signUp

  const onSubmit = async ({ email, password, newsletter = false }: FormikValues) => {
    mutate({
      accountType: 'majelan',
      accountDatas: {
        email: email.trim(),
        password,
      },
      gcu: true,
      newsletter,
      platform: 'web',
      lang: 'fr',
      partnership: query?.partnerServiceId ? true : false,
    } as any)
  }

  return (
    <>
      <Formik
        initialValues={
          isSignin
            ? { email: '', password: '' }
            : { email: '', email_confirmation: '', password: '', newsletter: false }
        }
        onSubmit={onSubmit}
        validationSchema={isSignin ? SignInValidationSchema : SignUpValidationSchema}
      >
        {({ isValid, dirty, isSubmitting, values }) => (
          <Form className="w-full space-y-4 max-w-sm">
            <Field
              name="email"
              className="h-12 text-cloud border border-solid border-cloud border-opacity-50 py-1 px-4 rounded-full w-full bg-palombe outline-none focus:border-white"
              required
              placeholder={getI18nKey({
                id: 'common.form.field.placeholder.email',
              })}
            />
            {!isSignin && (
              <Field
                name="email_confirmation"
                className="h-12 text-cloud border border-solid border-cloud border-opacity-50 py-1 px-4 rounded-full w-full bg-palombe outline-none focus:border-white"
                required
                placeholder={getI18nKey({
                  id: 'common.form.field.placeholder.emailConfirmation',
                })}
              />
            )}
            <div>
              <Field
                name="password"
                className="h-12 text-cloud border border-solid border-cloud border-opacity-50 py-1 px-4 rounded-full w-full bg-palombe outline-none focus:border-white"
                required
                placeholder={getI18nKey({
                  id: 'common.form.field.placeholder.password',
                })}
                type="password"
              />
              {isSignin && (
                <Link href="/auth/forgot">
                  <a className="text-white text-sm underline text-center mt-2 block ml-4">
                    {getI18nKey({ id: 'signInScreen.forgotPassword' })}
                  </a>
                </Link>
              )}
              {!isSignin && (
                <ul>
                  <li
                    className={classnames('flex items-center', {
                      'text-white': !values.password,
                      'text-green-500':
                        values.password && validatePasswordRule(values.password, 'length'),
                      'text-watermelon':
                        values.password && !validatePasswordRule(values.password, 'length'),
                    })}
                  >
                    <Icon name="check" />
                    <span className="text-sm">{getI18nKey({ id: 'common.password.length' })}</span>
                  </li>
                  <li
                    className={classnames('flex items-center', {
                      'text-white': !values.password,
                      'text-green-500':
                        values.password && validatePasswordRule(values.password, 'lowercase'),
                      'text-watermelon':
                        values.password && !validatePasswordRule(values.password, 'lowercase'),
                    })}
                  >
                    <Icon name="check" />
                    <span className="text-sm">
                      {getI18nKey({ id: 'common.password.lowercase' })}
                    </span>
                  </li>
                  <li
                    className={classnames('flex items-center', {
                      'text-white': !values.password,
                      'text-green-500':
                        values.password && validatePasswordRule(values.password, 'uppercase'),
                      'text-watermelon':
                        values.password && !validatePasswordRule(values.password, 'uppercase'),
                    })}
                  >
                    <Icon name="check" />
                    <span className="text-sm">
                      {getI18nKey({ id: 'common.password.uppercase' })}
                    </span>
                  </li>
                  <li
                    className={classnames('flex items-center', {
                      'text-white': !values.password,
                      'text-green-500':
                        values.password && validatePasswordRule(values.password, 'number'),
                      'text-watermelon':
                        values.password && !validatePasswordRule(values.password, 'number'),
                    })}
                  >
                    <Icon name="check" />
                    <span className="text-sm">{getI18nKey({ id: 'common.password.number' })}</span>
                  </li>
                  <li
                    className={classnames('flex items-center', {
                      'text-white': !values.password,
                      'text-green-500':
                        values.password && validatePasswordRule(values.password, 'special'),
                      'text-watermelon':
                        values.password && !validatePasswordRule(values.password, 'special'),
                    })}
                  >
                    <Icon name="check" />
                    <span className="text-sm">{getI18nKey({ id: 'common.password.special' })}</span>
                  </li>
                </ul>
              )}
            </div>
            {!isSignin && (
              <div>
                <CheckboxField
                  name="newsletter"
                  label={getI18nKey({ id: 'signUpScreen.form.newsletter.label' })}
                  description={getI18nKey({ id: 'signUpScreen.form.newsletter.description' })}
                />
              </div>
            )}
            <Button
              type="submit"
              buttonType="primary"
              disabled={!((dirty || isSubmitting) && isValid)}
              fullWidth
            >
              {submitLabel}
            </Button>
          </Form>
        )}
      </Formik>
      {isSignin && !pathname.includes('/auth/activation') && (
        <Link href="/auth/link">
          <a className="font-medium text-base text-white underline mt-4 inline-block">
            {getI18nKey({ id: 'signInScreen.auth.magicLink' })}
          </a>
        </Link>
      )}
    </>
  )
}
