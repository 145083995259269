import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

export interface IsOnAPP {
  isOnEmbed: string | boolean
  isOnApp: string | boolean
  isOnAuth: string | boolean
  isOnTerms: string | boolean
  isOnGift: string | boolean
  isOnOffers: string | boolean
}

export function useInOnApp(): IsOnAPP {
  const { asPath } = useRouter()

  const checkPaths = () => {
    const [isOnEmbed] = asPath?.match(/^\/player(.*)/) || [false]
    const [isOnApp] = asPath?.match(/^\/app(.*)/) || [false]
    const [isOnAuth] = asPath?.match(/^\/auth(.*)/) || [false]
    const [isOnTerms] = asPath?.match('/app/terms') || [false]
    const [isOnGift] = asPath?.match(/^\/gift(.*)/) || [false]
    const [isOnOffers] = asPath?.match(/^\/offers(.*)/) || [false]

    return { isOnApp, isOnAuth, isOnTerms, isOnGift, isOnOffers, isOnEmbed }
  }

  const ref = useRef<IsOnAPP>(checkPaths())

  useEffect(() => {
    ref.current = checkPaths()
  }, [asPath])

  return ref.current
}
