import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import qs from 'query-string'
import { nanoid } from 'nanoid'
import { flatten, join } from 'lodash'

import { ButtonProps } from './Button'
import { OAuthList, OAuthMetadata, OAuthType } from '../../../utils'
import { useAuth } from 'src/components/providers/AuthProvider'

export interface OAuthContainerProps extends Pick<OAuthMetadata, 'source'> {
  extended?: boolean
  backgroundColor?: string
  color?: string
}

export interface OAuthProps extends OAuthContainerProps, Pick<ButtonProps, 'href'> {
  type: OAuthType
  authMethodType: any //AuthParams
}

export interface LabelProps {
  title: string
}

export const OAuthButton = ({ type, extended = false, authMethodType, ...props }: OAuthProps) => {
  const { formatMessage: getI18nKey } = useIntl()
  const auth = useAuth()
  const { query, push, ...other } = useRouter()

  const { source, label, color, backgroundColor } = OAuthList[type]

  const isSignin = Boolean(authMethodType === 'signin')

  const mutate = isSignin ? auth.signIn : auth.signUp

  const onPressHandler = async () => {
    const authCommonData: any = {
      // deviceID: getUniqueId(),
      // os: getSystemVersion(),
      platform: 'web',
      lang: 'fr',
    }

    switch (type) {
      case 'google':
        const state = join(
          flatten(
            [
              ['sso', 'google'],
              ['type', authMethodType],
              ['code', query?.code || null],
              ['continue', query?.continue || null],
            ].filter(elem => {
              const [key, value] = elem

              return value ? true : false
            }),
          ),
          ',',
        )

        const queryCallback = qs.stringify({
          client_id: process.env.NEXT_PUBLIC_GOOGLE_WEB_CLIENT_ID,
          redirect_uri: `${window.location.origin}/auth/callback`,
          response_type: 'id_token token',
          scope: 'openid profile email',
          state,
          nonce: nanoid(),
        })

        push(`https://accounts.google.com/o/oauth2/v2/auth?${queryCallback}`)
        break

      case 'facebook': {
        const state = join(
          flatten(
            [
              ['sso', 'facebook'],
              ['type', authMethodType],
              ['code', query?.code || null],
              ['continue', query?.continue || null],
            ].filter(elem => {
              const [key, value] = elem

              return value ? true : false
            }),
          ),
          ',',
        )

        const queryCallback = qs.stringify({
          client_id: process.env.NEXT_PUBLIC_FACEBOOK_ID,
          redirect_uri: `${window.location.origin}/auth/callback`,
          response_type: 'token',
          scope: 'public_profile,email',
          state,
        })

        push(`https://www.facebook.com/v13.0/dialog/oauth?${queryCallback}`)
        break
      }

      case 'apple': {
        const state = join(
          flatten(
            [
              ['sso', 'apple'],
              ['type', authMethodType],
              ['code', query?.code || null],
              ['continue', query?.continue || null],
            ].filter(elem => {
              const [key, value] = elem

              return value ? true : false
            }),
          ),
          ',',
        )

        const queryCallback = qs.stringify({
          client_id: process.env.NEXT_PUBLIC_APPSTORE_SERVICE,
          redirect_uri: `${window.location.origin}/auth/callback`,
          response_type: 'code id_token',
          response_mode: 'fragment',
          state,
          nonce: nanoid(),
        })

        push(`https://appleid.apple.com/auth/authorize?${queryCallback}`)
        break
      }
      default:
        break
    }
  }

  return (
    <button
      className="no-underline px-10 min-h-45 flex w-full items-center justify-center rounded-3xl hover:bg-opacity-80 leading-none font-medium text-center text-sm transition-colors"
      style={{ backgroundColor: backgroundColor, color }}
      type="button"
      onClick={onPressHandler}
    >
      {source && (
        <div className="w-12 h-12">
          <img className="w-full h-full object-contain" src={source} alt="" />
        </div>
      )}
      <span className="font-medium text-center flex-grow text-sm">
        {getI18nKey({ id: `${label[authMethodType]}` })}
      </span>
    </button>
  )
}
