import { useIntl } from 'react-intl'

import { get } from 'lodash'

import { OAuthButton } from '../atoms/button/OAuthButton'
import { AuthEmail } from '../molecules/AuthEmail'

const defaultOAuthTypes = ['google', 'facebook', 'apple']

export interface OAuthBlockProps {
  authType?: any
  allowSSO?: boolean
}

const oauthLocaleKeys = {
  signin: {
    submit: 'signInScreen.button.email',
  },
  signup: {
    submit: 'signUpScreen.button.email',
  },
}

export const OAuthBlock = ({ authType = 'signup', allowSSO = true }: OAuthBlockProps) => {
  const { formatMessage: getI18nKey } = useIntl()

  const getLocalString = (key: string, prop: string) =>
    get(oauthLocaleKeys, `${key}.${prop}`, 'empty')

  const primaryAuthButtons = defaultOAuthTypes

  const renderOauthButtonsList = (auth: any) => {
    const key = `oauth-button_${auth}`

    if (auth === 'mail') {
      return (
        <AuthEmail
          key={key}
          submitLabel={getI18nKey({
            id: getLocalString(authType, 'submit'),
          })}
          authMethodType={authType}
        />
      )
    }

    return <OAuthButton type={auth} extended key={key} authMethodType={authType} />
  }

  return (
    <div className="flex flex-col items-center justify-center py-14 m-auto space-y-6">
      {allowSSO && primaryAuthButtons.map(renderOauthButtonsList)}
      <div className="w-full">
        {allowSSO && (
          <div className="flex items-center justify-center pt-3 pb-5">
            <div className="w-4 h-px bg-cloud" />
            <p className="text-xs mx-3 text-center font-medium text-cloud">
              {getI18nKey({ id: 'common.label.or' })}
            </p>
            <div className="w-4 h-px bg-cloud" />
          </div>
        )}

        <div className="w-full">{['mail'].map(renderOauthButtonsList)}</div>
      </div>
    </div>
  )
}
