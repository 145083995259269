import { Field } from 'formik'

export const CheckboxField = ({ name, label = null, description = null }) => {
  return (
    <label className="mb-6 my-6 2xl:text-base">
      <div className="flex w-full mt-5 space-x-3 items-center relative">
        <Field
          type="checkbox"
          name={name}
          className="p-2 appearance-none bg-cloud default:bg-cloud checked:bg-heliotrope checked:bg-input-checkbox-checked rounded-sm"
        />
        <div className="text-left">
          {label && <span className="block text-sm uppercase 2xl:text-base">{label}</span>}
          {description && <span className="text-xs 2xl:text-sm text-cloud">{description}</span>}
        </div>
      </div>
    </label>
  )
}
